<template>
    <section>
        <div class="container_services">
            <div>
                <img src="../assets/the concept.svg" width="82" height="42" alt="logo" />
            </div>

            <div class="font-weight-bold my-5 text-start" style="width:100%">
                {{ $t('Choose the services you want') }}...
            </div>

            <!-- Services -->
            <div>
                <v-tabs v-model="tab">
                    <div class="d-flex align-center " >
                        <v-btn depressed color="accent" v-for="(service, i) in checkboxes" :key="service.id" width="105"
                            height="27" :class="{ 'gray11 gray9--text': tab !== i }" class="rounded-pill ma-2"
                            @click="choseServices(service.id, i)">
                            {{ $t(service.name) }}</v-btn>
                    </div>
                    <!-- :style="{ height: $store.state.windowSize.y - 320 + 'px' }" -->
                    <v-tab-item class="services-box" >
                        <v-checkbox v-for="(checkbox, index) in checkboxes[tab].details" :key="index" v-model="checkboxesValue"
                            :value="checkbox.id" :label="checkbox.name" hide-details color="accent">
                            <template v-slot:label>
                                <v-row justify="space-between">
                                    <v-col cols="auto"> {{ checkbox.name }}</v-col>
                                    <v-col cols="auto"> {{ checkbox.price_with_vat | float }} {{ $t('Riyal') }}</v-col>
                                </v-row>
                            </template>
                        </v-checkbox>
                    </v-tab-item>
                </v-tabs>
            </div>
            <!-- End Services -->
            <div class="navigation d-flex justify-end mt-13 mb-3 px-5" style="width:90%">
                <v-btn depressed color="accent" :disabled="!checkboxesValue.length" width="131" height="45"
                    class="rounded-pill" @click="next">
                    {{ $t('next') }}</v-btn>
            </div>

        </div>
    </section>
</template>
  
<script>

export default {
    name: "Services",

    data: () => ({
        tab: 0,
        checkboxes: [],
        checkboxesValue: [],
    }),
    props: {
        navigationTab: { default: Function },
        data: { default: Object }
    },
    mounted() {
        console.log("data", this.data);
        this.groupingArray(this.data.services)
    },
    methods: {
        choseServices(id, index) {
            this.tab = index
        },
        next() {
            if (this.checkboxesValue.length) {
                this.navigationTab(1, { 'selectedServices': this.checkboxesValue },)
            }
        },
        groupingArray(arr) {
            let category_name = ''
            let update_index = 0;
            let data = []
            arr.forEach((item, index) => {
                if (item.category_name !== category_name) {
                    category_name = item.category_name
                    update_index = index
                    data.push({ id: item.id, name: category_name, details: [item] })
                } else if (item.category_name === category_name) {
                    data[update_index].details.push(item)
                }
            })
            this.checkboxes = data
        }
    },
};
</script>
<style lang="scss" scoped>
.container_services {
    text-align: center;
    padding: 30px;
    height: 100dvh;
    position: relative;
}

.v-input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--checkbox {
    border: 0.857143px solid #A0A0A0;
    border-radius: 5px;
    margin: 8px 0;
    padding: 5px;
}

.services-box {
    overflow: auto;
    overflow-x: hidden;
    height: 60dvh;
}

.navigation {
    position: absolute;
    bottom: 2%;
}
</style>