<template>
    <section class="confirmBonking">
        <div class="container_confirmBonking">
            <div class="header_logo">
                <img src="../assets/the concept.svg" width="82" height="42" alt="logo" />
            </div>


            <div class="confirmBonking_card d-flex flex-wrap justify-center align-center">
                <img src="../assets/ic_outline-done.svg" alt="Confirm Bonking" />
                <div class="font-weight-bold text-h6">
                    {{ $t('Your appointment has been booked, please wait a moment') }}
                </div>
                <div class="py-5">{{ $t('The appointment is on') }} {{ day }} {{ $t('the hour') }} {{ data.params.time }}</div>
                <div class="font-weight-bold text-h6">{{ $t('We are pleased to serve you') }}</div>
            </div>
        </div>
    </section>
</template>
  
<script>

export default {
    name: "ConfirmBonking",

    data: () => ({
    }),
    props: {
        navigationTab: { default: Function },
        data: { default: Object }
    },
    components: {
    },
    computed: {
        day() {
            return new Date().toLocaleDateString("ar", {
                weekday: "short",
                year: "numeric",
                month: "numeric",
                day: "numeric",
            })
        }
    },
    mounted() {
        this.day()
    },

};
</script>
<style lang="scss" scoped>
.container_confirmBonking {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 14px;
    height: 100dvh;
    position: relative;

}

.header_logo {
    position: absolute;
    top: 2%;
}
</style>