<template>
  <section>
    <div>
      <v-tabs v-model="tab" v-show="false">
        <v-tab></v-tab>
        <v-tab></v-tab>
        <v-tab></v-tab>
        <v-tab></v-tab>
        <v-tab></v-tab>
        <v-tab></v-tab>
        <v-tab></v-tab>
        <v-tab></v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item>
          <Welcome :navigationTab="navigationTab" />
        </v-tab-item>
        <v-tab-item>
          <Services :navigationTab="navigationTab" :data="data" />
        </v-tab-item>
        <v-tab-item>
          <SelectBarber :navigationTab="navigationTab" :data="data" />
        </v-tab-item>
        <v-tab-item>
          <AppointmentTime :navigationTab="navigationTab" :data="data" />
        </v-tab-item>
        <v-tab-item>
          <CheckOut :navigationTab="navigationTab" :data="data" />
        </v-tab-item>
        <v-tab-item>
          <Registration :navigationTab="navigationTab" :data="data" />
        </v-tab-item>
        <v-tab-item>
          <ConfirmLoading :navigationTab="navigationTab" :data="data" />
        </v-tab-item>
        <v-tab-item>
          <ConfirmBonking :navigationTab="navigationTab" :data="data" />
        </v-tab-item>
      </v-tabs-items>

    </div>

  </section>
</template>

<script>

import AppointmentTime from '@/components/AppointmentTime.vue';
import CheckOut from '@/components/CheckOut.vue';
import ConfirmBonking from '@/components/ConfirmBonking.vue';
import ConfirmLoading from '@/components/ConfirmLoading.vue';
import Registration from '@/components/Registration.vue';
import SelectBarber from '@/components/SelectBarber.vue';
import Services from '@/components/Services.vue';
import Welcome from '@/components/Welcome.vue';
export default {
  name: "TheConceptQrCode",

  data: () => ({
    pageData: {
      screen_code: "03-004",
      url: null,
      controlRoute: "/website/qr",
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 15,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      queryParam: '?'
    },
    tab: null,
    data: {},
  }),
  components: {
    Welcome,
    Services,
    SelectBarber,
    AppointmentTime,
    CheckOut,
    Registration,
    ConfirmLoading,
    ConfirmBonking
  },
  computed: {},
  mounted() {
  },
  watch: {

  },
  methods: {
    navigationTab(num, data) {
      this.tab = this.tab + num
      if (data) {
        this.data = { ...this.data, ...data }
      }
    }
  },
};
</script>
