<template>
    <section>
        <div class="container_services">
            <div class="pa-3">
                <img src="../assets/the concept.svg" width="82" height="42" alt="logo" />
            </div>

            <div class="font-weight-bold my-5 text-start px-5" style="width:100%">
                {{ $t('Choose the barber you want') }}...
            </div>

            <!-- Services -->
            <!-- :style="{ height: $store.state.windowSize.y - 260 + 'px' }" -->
            <div class="services_box d-flex flex-wrap justify-center align-center"
                >
                <div class="barberCard font-weight-medium text-caption gray7--text ma-1"
                    :class="{ 'barberCard_active': barber.id == barber_id }" v-for="barber in data.barbers" :key="barber.id"
                    @click="select(barber)">
                    <v-img :src="$api.serverUrl + barber.image" class="mb-1" width="51px" height="51px">
                        <template v-slot:placeholder>
                            <v-img :src="error" class="mt-2" width="51px" height="51px" />
                        </template>
                    </v-img>
                    <span class="my-1">{{ barber.name }}</span>
                    <span class="overline-caption my-1">{{ $t('Available now') }}</span>
                </div>
            </div>
            <!-- End Services -->
            <div class="navigation d-flex justify-space-between mt-13 mb-3" style="width:90%">
                <v-btn text elevation="0" width="101" height="45" class="rounded-pill" @click="previous">
                    {{ $t('previous') }}</v-btn>

                <v-btn depressed color="accent" width="101" :disabled="!barber_id" :loading="loading" height="45" class="rounded-pill"
                    @click="next">
                    {{ $t('next') }}</v-btn>
            </div>

        </div>
    </section>
</template>
  
<script>

export default {
    name: "SelectBarber",

    data: () => ({
        error: require('@/assets/Pic.svg'),
        barber_id: null,
        loading: false,
        isSelected: false,
    }),
    props: {
        navigationTab: { default: Function },
        data: { default: Object }
    },
    methods: {
        dayDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        next() {
            if (!this.isSelected) {
                this.isSelected = true;
                let params = {
                    services: this.data.selectedServices,
                    barber_id: this.barber_id,
                    booking_date: this.dayDate(),
                }
                console.log('params', params);
                this.loading=true;
                this.$api.POST_METHOD(`get_available_times`, params).then((response) => {
                    this.loading=false;
                    if (response.check) {
                        console.log('response', response);
                        setTimeout(() => this.isSelected = false, 1000)
                        this.navigationTab(1, { 'params': params, 'date': response.data })
                    }
                })
            }
        },
        select(barber) {
            this.barber_id = barber.id
            this.next()
        },
        previous() {
            this.navigationTab(-1)
        }
    },

};
</script>
<style lang="scss" scoped>
.container_services {
    text-align: center;
    padding: 14px;
    height: 100dvh;
}

.v-input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--checkbox {
    border: 0.857143px solid #A0A0A0;
    border-radius: 5px;
    margin: 8px 0;
    padding: 5px;
}

.services_box {
    overflow: auto;
    align-content: flex-start;
}

.barberCard {
    border: 0.807018px solid #D7BB89;
    border-radius: 4.84211px;
    width: 128px;
    height: 124px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.barberCard:hover {
    background-color: #FFE5B6;
    color: black !important;
}

.barberCard_active {
    background-color: #FFE5B6;
    color: black !important;
}


.navigation {
    position: absolute;
    bottom: 2%;
}
</style>