import Vue from "vue";
import Vuex from "vuex";
import Settings from "./Settings";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    endpointURL: "https://api.qawaemerp.com/", //  live
    // endpointURL: "https://qawaem-erp-api.cs-testing.com/api/booking/", //  Testing
    app_version: process.env.VUE_APP_VERSION,
    'access-token-booking': "BookingxcVBybDQhtDRqPxFUkpe1JVsZqyGGBR1v1KqendQ2qcLbqXB9hgqin41uRJ3qGTwD0L1hmRS29a0gXbKf8EBVcBy7ofvhKviDzJ88u8p6Hs9mNncXZSXOxeSQ7Ka7tlEhCnBMFtVSgZbuYf9sBubou5pWBxu1okFMC2ksCfk3jxdTQmeHq5xBlOL3pmkqq32L26LX1EB",
    apiAccess:
      "xcVBybDQhtDRqPxFUkpe1JVsZqyGGBR1v1KqendQ2qcLbqXB9hgqin41uRJ3qGTwD0L1hmRS29a0gXbKf8EBVcBy7ofvhKviDzJ88u8p6Hs9mNncXZSXOxeSQ7Ka7tlEhCnBMFtVSgZbuYf9sBubou5pWBxu1okFMC2ksCfk3jxdTQmeHq5xBlOL3pmkqq32L26LX1EB",
    clientID: '',
    isAuth: false,
    splashScreen: true,
    offline: false,
    language: localStorage.getItem("language") == "en" ? "en" : "ar",
    sidebarIsOpen: false,
    accessToken: null,
    activeSection: 1,
    isHomeSection: false,
    isAboutSection: false,
    isAppSection: false,
    isFeaturesSection: false,
    isFeaturesSectionFirst: false,
    isFeaturesSectionLast: false,
    isWatchVideoSection: false,
    isRequestSection: false,
    sections: {
      landing_section: null,
      watch_video: null,
      about_us: null,
    },
    seo: [],
    register_briefs: [],
    common_questions: [],
    brands: [],
    new_arrivals: [],
    userData: {},
    requiredRule: [(v) => !!v || Vue.prototype.i18n.t("field is required")],
    notRequiredRule: [],
    website_basic_data: {
      about: null,
      mission: null,
      vision: null,
      goal: null,
      phone: null,
      email: null,
      whatsapp: null,
      vat_registration_number: null,
      facebook_link: null,
      linkedin_link: null,
      twitter_link: null,
      youtube_link: null,
      instagram_link: null,
      google_plus_link: null,
      main_logo: null,
      footer_title: null,
      footer_logo: null,
      footer_desc: null,
      website_policy: null,
      delivery_info: null,
      return_policy: null,
      gift_cards_policy: null,
      link_in_app_store: null,
      link_in_google_play: null,
    },
    windowSize: { x: 0, y: 0, offsetTop: 0 },
    showSnackbar: false,
    snackbarType: "info",
    snackbarTime: 4500,
    snackbarTitle: null,
    snackbarMessages: [],
  },
  mutations: {
    SET_DEFAULTS_DATA(state, data) {
      // Social Links & Sections Data

      const landing_section = data.sections.filter(
        (obj) => obj.code === "landing_section"
      );
      const watch_video = data.sections.filter(
        (obj) => obj.code === "watch_video"
      );
      const about_us = data.sections.filter((obj) => obj.code === "about_us");
      const register_page = data.sections.filter(
        (obj) => obj.code === "register_page"
      );

      state.sections = {
        landing_section: landing_section[0],
        watch_video: watch_video[0],
        about_us: about_us[0],
        register_page: register_page[0],
      };

      state.features = data.features;
      (state.register_briefs = data.register_briefs),
        (state.new_arrivals = data.new_arrivals),
        (state.brands = data.brands),
        (state.common_questions = data.common_questions),
        (state.seo = data.seo),
        (state.website_basic_data = data.basic_data[0] || {
          about: null,
          mission: null,
          vision: null,
          goal: null,
          phone: null,
          email: null,
          whatsapp: null,
          vat_registration_number: null,
          facebook_link: null,
          linkedin_link: null,
          twitter_link: null,
          youtube_link: null,
          instagram_link: null,
          google_plus_link: null,
          main_logo: null,
          footer_title: null,
          footer_logo: null,
          footer_desc: null,
          website_policy: null,
          delivery_info: null,
          return_policy: null,
          gift_cards_policy: null,
          link_in_app_store: null,
          link_in_google_play: null,
        });
      //
      state.splashScreen = false;
    },
    UPDATE_CLIENT(state,data){
      state.clientID = data
    }
  },
  getters: {},
  actions: {
    checkAuth({ state }) {
      // state.splashScreen = true;
      // this.$axios.defaults.config.baseURL = ''
      state.isAuth = !!localStorage.getItem(
        Vue.prototype.$global.CRYPT("cloud", "access-token")
      );
      if (!state.isAuth) {
        //  state.splashScreen = false;
        // this.app.router.push('/login')
      } else {
        state.accessToken = Vue.prototype.$global.DECRYPT(
          "cloud",
          localStorage.getItem(
            Vue.prototype.$global.CRYPT("cloud", "access-token")
          )
        );
        axios.defaults.headers.common.Authorization =
          "Bearer " + state.accessToken;
        // state.isAuth = true
      }
    },
    getUserData() {
      Vue.prototype.$api.GET_METHOD(`get_my_profile`).then((response) => {
        if (response.check) {
          console.log("response.data", response.data);
          Vue.prototype.$api.SET_AUTH(response.data);
        }
      });
    },

    checkAppLanguage({ state }) {
      return state.language;
    },
    checkLang({ state }) {
      Vue.prototype.$i18n.locale = localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "ar";
      state.language = localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "ar";
      state.Settings.language = localStorage.getItem("language")
        ? localStorage.getItem("language")
        : "ar";
    },
    getWebsiteData({ state, commit }) {
      axios.defaults.baseURL =
        state.endpointURL + `api/v1/${state.Settings.language}/front/`;
      setTimeout(() => {
        Vue.prototype.$api.GET_METHOD(`home`).then((response) => {
          if (response.check) {
            commit("SET_DEFAULTS_DATA", response.data);
          }
        });
      }, 1);
    },
    update_client_id({ commit },data){
      commit("UPDATE_CLIENT_ID",data)
    }
  },

  modules: { Settings },
});
